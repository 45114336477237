import React from "react";
import LinkedIn from "../../assets/sociale/icon-in.svg";

function LinkedInLogo() {
  return (
    <a href="https://www.linkedin.com/company/sunroof-ab" target="_blank" rel="noreferrer noopener">
      <div className="h-auto w-auto">
        <img alt="" src={LinkedIn}></img>
      </div>
    </a>
  );
}

export default LinkedInLogo;
