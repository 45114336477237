import React from "react";
import Instagram from "../../assets/sociale/icon-ig.svg";

function InstagramLogo() {
  return (
    <a href="https://www.instagram.com/sunroof.intl/" target="_blank" rel="noreferrer noopener">
      <div className="h-auto w-auto">
        <img alt="" src={Instagram}></img>
      </div>
    </a>
  );
}

export default InstagramLogo;
