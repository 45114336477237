import React from "react";
import Youtube from "../../assets/sociale/icon-yt.svg";

function YoutubeLogo() {
  return (
    <a href="https://www.youtube.com/channel/UCZp5qEkgdBey8D3LA3dIIyg" target="_blank" rel="noreferrer noopener">
      <div className="h-auto w-auto">
        <img alt="" src={Youtube}></img>
      </div>
    </a>
  );
}

export default YoutubeLogo;
