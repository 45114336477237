import React from "react";
import FrontPageLogoGreyBackground from "./FrontPageLogoGreyBackground";


function Header({setIsMenuPopUpOpen, isMenuPopUpOpen}) {
  return (
    <div className="h-full w-full grid grid-cols-12">
      <div className="grid col-start-2 col-span-3 sm:col-start-2 sm:col-span-2 md:col-start-2 md:col-span-2 lg:col-start-3 lg:col-span-1 xl:col-span-1 xl:col-start-3">
        <FrontPageLogoGreyBackground />
      </div>
    </div>
  );
}

export default Header;
