import React from "react";

function PrizePoolYellowCircle() {
  return (
    <div className="h-10 sm:h-14 w-full bg-yellow md:mt-10 mb-10 xl:mb-0 lg:rounded-full flex items-center lg:absolute lg:-bottom-10 xl:bottom-0 lg:transform lg:rotate-6 lg:z-10 lg:h-60 lg:w-60 xl:h-72 xl:w-72">
      <div className="h-2/5 w-full bg-inherit flex items-center lg:flex-col lg:items-center lg:justify-evenly justify-center">
        <div className="font-openSansLight font-light text-graphite lg:text-2xl md:mr-2 lg:mr-0 xl:mr-2 text-lg sm:text-2xl">Announcing</div>
        <div className="font-openSansLight font-semibold text-graphite ml-2 flex justify-center md:ml-0 lg:text-4xl xl:text-5xl sm:text-2xl">Winners!</div>
      </div>
    </div>
  );
}

export default PrizePoolYellowCircle;
