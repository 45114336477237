import React from "react";

function HonourableMentionProjectDescription() {
  return (
    <div className="">
      The variety of needs and changing requirements became the prelude to work.
      It is not difficult to imagine a situation where the house becomes too
      small as the family has grown. The modularity of the assumption ensures
      that the needs are adjusted to the user. Creating a few basic modules
      guarantees that the house can be adapted to your own needs, which is also
      simple, such as a puzzle or blocks.
      <br />
      <br />
      The ground floor of the house is also not accidental. Thanks to this, it
      becomes safe and accessible also for people with all disabilities. Stairs
      are an unnecessary barrier and obstacle in everyday life, so I decided to
      avoid them. Also, the lack of thresholds and the use of wide passages and
      spacious rooms make it easier to function in this house.
      <br />
      <br />
      Considering all the main aspects, the finishing and construction material
      is moon wood for its functions. This type of wood is fire resistant, has a
      low swelling ratio, is resistant to mold and fungi, is not attacked by
      pests and does not require chemical analysis. The house built in this
      system is naturally insulated and cools down much slower than buildings in
      concrete construction. As a result, the use of additional wall layers can
      be limited.
    </div>
  );
}

export default HonourableMentionProjectDescription;
