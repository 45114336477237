import React from "react";

function WinnersCaption() {
  return (
    <div className="h-auto w-full bg-inherit flex font-openSansLight font-semibold text-graphite text-2xl">
      Winners
    </div>
  );
}

export default WinnersCaption;
