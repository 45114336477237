import React from "react";
import SunRoofLandingPage from "./components/page/SunRoofLandingPage.js";

function App() {
    return (
        <SunRoofLandingPage />
    );
}

export default App;
