import React from "react";
import { classNames } from "../../utils.js";

function WinnersYearMolecule({ ...props }) {
    return (
        <div className="h-auto 2xl:w-1/2 xl:w-3/4 bg-inherit flex flex-row">
            <button className="h-auto bg-inherit flex flex-row border justify-center border-grey mr-3" onClick={() => props.setEdition("2021")}>
                <div className={classNames("w-full border-b-4  p-3 flex justify-center font-openSansLight font-light text-graphite text-lg", props.edition === "2021" ? "border-yellow" : "border-gray")}>
                    Winners 2021
                </div>
            </button>
            <button className="h-auto bg-inherit flex flex-row border justify-center border-grey" onClick={() => props.setEdition("2020")}>
                <div className={classNames("w-full border-b-4 p-3 flex justify-center font-openSansLight font-light text-graphite text-lg", props.edition === "2020" ? "border-yellow" : "border-gray")}>
                    Winners 2020
                </div>
            </button>
        </div>
    );
}

export default WinnersYearMolecule;
