import React from "react";

function JuryTitle(props) {
  return (
    <div className="h-auto w-full font-openSansLight font-light text-graphite text-sm flex sm:justify-center lg:justify-start">
      {props.title}
    </div>
  );
}

export default JuryTitle;
