import React from "react";

function HonourableMentionNameAndPriceCaption() {
  return (
    <div className="h-1/4 w-full sm:mb-5 lg:mb-0 bg-inherit flex flex-col">
      <div className="h-auto w-full bg-inherit flex flex-col">
        <div className="h-auto mt-10 mb-3 font-openSansLight font-semibold text-graphite">
          Honourable mention prize of € 1000
        </div>
        <div className="h-auto w-full flex flex-row">
          <div className="h-auto w-auto mr-6 font-openSansLight font-light text-graphite text-sm">
            Designer:
          </div>
          <div className="h-auto w-auto font-openSansLight font-light text-graphite text-sm">
            Arch. Iga Soczawa
          </div>
        </div>
        <div className="h-auto w-full mt-1 flex flex-row">
          <div className="h-auto w-auto mr-9 font-openSansLight font-light text-graphite text-sm">
            Project:
          </div>
          <div className="h-auto w-auto font-openSansLight font-light text-graphite text-sm">
            Moon House
          </div>
        </div>
      </div>
    </div>
  );
}

export default HonourableMentionNameAndPriceCaption;
