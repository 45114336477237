import React from "react";
import AboutCompetitionCaption from "../atoms/AboutCompetitionCaption.js";
import AboutCompetitionPDF from "../atoms/AboutCompetitionPDF.js";
import AboutCompetitionText from "../atoms/AboutCompetitionText.js";

function AboutCompetitionOrganism() {
  return (
    <div
      id="AboutCompetition"
      className="h-auto w-full bg-white flex justify-center"
    >
      <div className="h-full lg:w-3/4 pl-5 pr-5 sm:w-full sm:pl-10 sm:pr-10 lg:pl-0 lg:pr-0 xl:pl-20 pt-10 xl:pr-20 pb-10 bg-inherit flex flex-col">
        <AboutCompetitionCaption />
        <AboutCompetitionText />
        <AboutCompetitionPDF />
      </div>
    </div>
  );
}

export default AboutCompetitionOrganism;
