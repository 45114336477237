import React, { useState } from "react";
import { classNames } from "../../utils";

function ProjectSubmissionPartnersNewsletterConsent({
  SetIsPartnersNewsletterConsentTicked,
  IsPartnersNewsletterConsentTicked,
  ...props
}) {
  const [IsPartnersNewsletterConsentOpen, SetIsPartnersNewsletterConsentOpen] =
    useState(false);

  return (
    <div className="h-full w-full mb-3 bg-inherit flex justify-between items-center font-openSans font-light text-graphite text-xs">
      <div className="h-6 w-6 mr-3 bg-inherit border border-black flex cursor-pointer">
        <div
          onClick={props.onClickItem}
          className={classNames(
            "h-full w-full flex justify-center items-center",
            IsPartnersNewsletterConsentTicked ? "bg-yellow" : "bg-white"
          )}
        >
          <div
            className={classNames(
              "",
              IsPartnersNewsletterConsentTicked ? "flex" : "hidden"
            )}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-graphite"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="h-auto w-11/12">
        {IsPartnersNewsletterConsentOpen === true ? (
          <span>
            I hereby grant my consent for receiving commercial information by
            electronic means from partners and media partners of the
            competition, including the newsletter.
          </span>
        ) : (
          <span>
            I sign up for the newsletter from partners and media partners of the
            competition and{" "}
          </span>
        )}
        <div
          className="inline font-semibold underline cursor-pointer"
          onClick={() => {
            SetIsPartnersNewsletterConsentOpen(
              !IsPartnersNewsletterConsentOpen
            );
          }}
        >
          {IsPartnersNewsletterConsentOpen === true
            ? null
            : "I accept the the full wording of the consent."}
        </div>{" "}
        <span>(Optional)</span>
      </div>
      <br />
      <br />
    </div>
  );
}

export default ProjectSubmissionPartnersNewsletterConsent;
