import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import One from "../../assets/2020WoodenEco/1.png"
import Two from "../../assets/2020WoodenEco/2.png"
import Three from "../../assets/2020WoodenEco/3.png"
import Four from "../../assets/2020WoodenEco/4.png"
import "./CarouselItemsStyling.css";

function DemoCarouselMainPrize() {
    return (
        <Carousel showThumbs={false} showStatus={false}>
            <div className="h-auto w-full sm:mb-0 2xl:h-1/2 xl:h-2/5 sm:w-full flex">
                <img
                    src={One}
                    alt=""
                    className="h-full w-full mx-auto flex"
                />
            </div>
            <div className="h-auto w-full sm:mb-0 2xl:h-1/2 xl:h-2/5 sm:w-full flex">
                <img
                    src={Two}
                    alt=""
                    className="h-full w-full mx-auto flex"
                />
            </div>
            <div className="h-auto w-full sm:mb-0 2xl:h-1/2 xl:h-2/5 sm:w-full flex">
                <img
                    src={Three}
                    alt=""
                    className="h-full w-full mx-auto flex"
                />
            </div>
            <div className="h-auto w-full sm:mb-0 2xl:h-1/2 xl:h-2/5 sm:w-full flex">
                <img
                    src={Four}
                    alt=""
                    className="h-full w-full mx-auto flex"
                />
            </div>
        </Carousel>
    );
}

export default DemoCarouselMainPrize;
