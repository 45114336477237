import React from "react";

function HonoraryMediaPartnersCaption() {
  return(
    <div className="h-auto w-full mt-3 sm:mt-0 lg:ml-2 mb-4 bg-inherit-300 flex font-openSansLight font-light text-graphite text-sm">
      MEDIA PARTNERS
    </div>
  );
}

export default HonoraryMediaPartnersCaption;