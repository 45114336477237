import React, { useState } from "react";
import MainPageTemplate from "../templates/MainPageTemplate.js";
import MenuOpen from "../templates/MenuOpen.js";
import { classNames } from "../../utils.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LogInModalPopUp from "../templates/LogInModalPopUp.js";
import CreateAccount from "../templates/CreateAccount.js";
import ScrollToTop from "../atoms/ScrollToTop.js";
import { restore } from "../../session.js";

function SunRoofLandingPage() {
    const [isMenuPopUpOpen, setIsMenuPopUpOpen] = useState(false);
    const [IsLogInModalOpen, SetIsLogInModalOpen] = useState(false);
    const [IsUserLoggedIn, SetIsUserLoggedIn] = useState(restore('token.uuid', null) !== null);
    const [isAccountBeingRegistered, SetIsAccountBeingRegistered] = useState(false);

    return (
        <Router>
            <ScrollToTop />
            <div
                className={classNames(
                    "min-h-screen w-full overflow-x-hidden font-openSans"
                )}
            >
                <Switch>
                    <Route exact path="/">
                        <MainPageTemplate
                            setIsMenuPopUpOpen={setIsMenuPopUpOpen}
                            isMenuPopUpOpen={isMenuPopUpOpen}
                            SetIsLogInModalOpen={SetIsLogInModalOpen}
                            IsLogInModalOpen={IsLogInModalOpen}
                            SetIsAccountBeingRegistered={SetIsAccountBeingRegistered}
                            isAccountBeingRegistered={isAccountBeingRegistered}
                            SetIsUserLoggedIn={SetIsUserLoggedIn}
                            IsUserLoggedIn={IsUserLoggedIn}
                        />
                        <MenuOpen
                            setIsMenuPopUpOpen={setIsMenuPopUpOpen}
                            isMenuPopUpOpen={isMenuPopUpOpen}
                            SetIsLogInModalOpen={SetIsLogInModalOpen}
                            IsLogInModalOpen={IsLogInModalOpen}
                            SetIsUserLoggedIn={SetIsUserLoggedIn}
                            IsUserLoggedIn={IsUserLoggedIn}
                        />
                        {
                            /*
                            <LogInModalPopUp
                                IsLogInModalOpen={IsLogInModalOpen}
                                SetIsLogInModalOpen={SetIsLogInModalOpen}
                                isActive={IsLogInModalOpen}
                                onClickItem={() => {
                                    SetIsLogInModalOpen(!IsLogInModalOpen);
                                }}
                                onClickLogIn={() => {
                                    SetIsUserLoggedIn(!IsUserLoggedIn);
                                }}
                                SetIsUserLoggedIn={SetIsUserLoggedIn}
                                IsUserLoggedIn={IsUserLoggedIn}
                            />
                            */
                        }
                        
                    </Route>
                    {
                        /*
                            <Route exact path="/account">
                                <CreateAccount
                                    setIsMenuPopUpOpen={setIsMenuPopUpOpen}
                                    isMenuPopUpOpen={isMenuPopUpOpen}
                                    SetIsAccountBeingRegistered={SetIsAccountBeingRegistered}
                                    isAccountBeingRegistered={isAccountBeingRegistered}
                                />
                                <LogInModalPopUp
                                    IsLogInModalOpen={IsLogInModalOpen}
                                    SetIsLogInModalOpen={SetIsLogInModalOpen}
                                    isActive={IsLogInModalOpen}
                                    isLoggedIn={IsUserLoggedIn}
                                    SetIsUserLoggedIn={SetIsUserLoggedIn}
                                    IsUserLoggedIn={IsUserLoggedIn}
                                    onClickItem={() => {
                                        SetIsLogInModalOpen(!IsLogInModalOpen);
                                    }}
                                    onClickLogIn={() => {
                                        SetIsUserLoggedIn(!IsUserLoggedIn);
                                    }}
                                />
                                <MenuOpen
                                    setIsMenuPopUpOpen={setIsMenuPopUpOpen}
                                    isMenuPopUpOpen={isMenuPopUpOpen}
                                    SetIsLogInModalOpen={SetIsLogInModalOpen}
                                    IsLogInModalOpen={IsLogInModalOpen}
                                    SetIsUserLoggedIn={SetIsUserLoggedIn}
                                    IsUserLoggedIn={IsUserLoggedIn}
                                />
                            </Route>
                        */
                    }                    
                </Switch>
            </div>
        </Router>
    );
}

export default SunRoofLandingPage;
