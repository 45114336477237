import React from "react";

function JuryOfCompetitionCaption() {
  return (
    <div className="h-auto w-full mb-10 ml-3 sm:ml-8 md:ml-12 lg:ml-0  bg-inherit flex font-openSansLight font-semibold text-graphite text-2xl">
      Jury of competition
    </div>
  );
}

export default JuryOfCompetitionCaption;
