import React from "react";
import { classNames } from "../../utils";
import { useLocation } from "react-router-dom";

function FrontPageHamburgerMenuButton({ setIsMenuPopUpOpen, isMenuPopUpOpen }) {
    const location = useLocation();

    return (
        <button
            className={classNames(
                "w-auto",
                "right-7 sm:right-14 lg:right-28 xl:right-40 2xl:right-72 sm:mt-5 md:mt-10 fixed z-40"
            )}
            onClick={() => setIsMenuPopUpOpen(!isMenuPopUpOpen)}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className={classNames(
                    "h-12 w-12 md:h-16 md:w-16 rounded-none mt-8 lg:mt-0",
                    location.pathname === "/account"
                        ? "text-black"
                        : "bg-hamburger text-black"
                )}
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                />
            </svg>
        </button>
    );
}

export default FrontPageHamburgerMenuButton;
