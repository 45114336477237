import React from "react";
import { useHistory } from "react-router-dom";
import { store } from "../../session";
import { classNames } from "../../utils";
import MenuLinkOption from "../atoms/MenuLinkOption";

function MenuOpen({
    setIsMenuPopUpOpen,
    isMenuPopUpOpen,
    SetIsLogInModalOpen,
    IsLogInModalOpen,
    SetIsUserLoggedIn,
    IsUserLoggedIn,
}) {
    const history = useHistory();

    const LogIn = () => {
        if (IsUserLoggedIn) {
            store({});
            history.push("/");
        } else {
            SetIsLogInModalOpen(true);
        }
        setIsMenuPopUpOpen(false);
        SetIsUserLoggedIn(!SetIsUserLoggedIn);
    };

    return (
        <div
            className={classNames(
                "h-full w-full lg:w-1/3 bg-white flex flex-col overflow-y-hidden fixed inset-y-0 right-0 transform transition duration-500 ease-in-out z-50",
                isMenuPopUpOpen ? "" : "translate-x-full"
            )}
        >
            <div className="h-1/4 w-full flex ">
                <div className="h-full w-1/2 pl-12 font-openSansLight font-semibold text-graphite text-4xl flex items-end">
                    Menu
                </div>
                <button
                    onClick={() => setIsMenuPopUpOpen(!isMenuPopUpOpen)}
                    className="mobile-menu h-full w-1/2 flex text-4xl items-end justify-end pr-10"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-12 w-12"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
            <div className="h-2/4 w-full flex flex-col items-start pl-10 pt-10 text-2xl">
                <div className="h-1/6 w-full flex flex-row items-center">
                    <MenuLinkOption
                        onClick={(e) => setIsMenuPopUpOpen(false)}
                        text="About Competition"
                        href="/#AboutCompetition"
                    />
                </div>
                <div className="h-1/6 w-full flex flex-row items-center">
                    <MenuLinkOption
                        onClick={(e) => setIsMenuPopUpOpen(false)}
                        text="Jury of competition"
                        href="/#JuryOfCompetition"
                    />
                </div>
                <div className="h-1/6 w-full flex flex-row items-center">
                    <MenuLinkOption
                        onClick={(e) => setIsMenuPopUpOpen(false)}
                        text="Winners"
                        href="/#Winners"
                    />
                </div>
                {
                    /*
                        <div className="h-1/6 w-full flex flex-row items-center">
                            {
                                IsUserLoggedIn ? (
                                    <MenuLinkOption
                                        onClick={(e) => setIsMenuPopUpOpen(false)}
                                        text="Your account"
                                        href="/account"
                                    />
                                ) : (
                                    <MenuLinkOption
                                        onClick={(e) => setIsMenuPopUpOpen(false)}
                                        text="Create account"
                                        href="/#CreateAccount"
                                    />
                                )
                            }
                        </div>
                        <div className="h-1/6 w-full flex flex-row items-center">
                            <button
                                type="button"
                                className="h-full w-full pl-3 bg-inherit flex justify-start items-center cursor-pointer font-light text-graphite text-3xl hover:bg-grey hover:font-semibold"
                                onClick={LogIn}
                            >
                                {IsUserLoggedIn === true ? "Log out" : "Log in"}
                            </button>
                        </div>
                    */
                }
            </div>
        </div>
    );
}

export default MenuOpen;
