import React from "react";
import One from "../../assets/2020MoonHouse/d1.png"
import Two from "../../assets/2020MoonHouse/d2.png"
import Three from "../../assets/2020MoonHouse/d3.png"
import Four from "../../assets/2020MoonHouse/d4.png"

function HonourableMentionPhotos() {
    return (
        <div className="h-full w-full lg:pl-6 lg:pt-4 xl:pt-20 bg-inherit flex flex-row flex-wrap justify-evenly sm:justify-center xl:items-end">
            <div className="h-auto xl:h-1/2 w-5/12 sm:w-1/2 sm:pr-2 sm:pb-2 flex">
                <img alt="" style={{ width: "100%" }} src={One}></img>
            </div>
            <div className="h-auto xl:h-1/2 w-5/12 sm:w-1/2 sm:pr-2 sm:pb-2 flex">
                <img alt="" style={{ width: "100%" }} src={Two}></img>
            </div>
            <div className="h-auto xl:h-1/2 w-5/12 sm:w-1/2 sm:pr-2 sm:pb-2 flex">
                <img alt="" style={{ width: "100%" }} src={Three}></img>
            </div>
            <div className="h-auto xl:h-1/2 w-5/12 sm:w-1/2 sm:pr-2 sm:pb-2 flex">
                <img alt="" style={{ width: "100%" }} src={Four}></img>
            </div>
        </div>
    );
}

export default HonourableMentionPhotos;
