import React from "react";

function CompetitionBIMobjectDesignCaption() {
  return (
    <div className="font-openSansLight font-light text-graphite mt-3 sm:mt-0 mb-5 md:mb-0 md:mt-6 lg:mt-0 text-sm sm:text-xl md:text-lg lg:text-xl xl:text-2xl">
      Create the House or Carport of the Future with <br/>a SunRoof solar roof using BIMobject <br/>and Grenton Smart Home System.
    </div>
  );
}

export default CompetitionBIMobjectDesignCaption;
