import React from "react";
import Header from "../molecules/Header.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./CompetitionIntroduction.css";

function CompetitionIntroduction({ setIsMenuPopUpOpen, isMenuPopUpOpen }) {
    return (
        <section className="section">
            <Carousel
                autoPlay={true}
                interval="6000"
                infiniteLoop={true}
                stopOnHover={false}
                showThumbs={false}
                showStatus={false}
            >
                <div className="h-64 sm:h-screen-1/2 lg:h-screen-3/4 w-full bg-white lg:pl-5 lg:pr-5 flex flex-col items-center">
                    <div className="h-full w-full flex z-10">
                        <div className="h-full w-full bg-house-img bg-no-repeat bg-cover bg-center flex z-10">
                            <Header
                                setIsMenuPopUpOpen={setIsMenuPopUpOpen}
                                isMenuPopUpOpen={isMenuPopUpOpen}
                            />
                        </div>
                    </div>
                </div>
                <div className="h-64 sm:h-screen-1/2 lg:h-screen-3/4 w-full bg-white lg:pl-5 lg:pr-5 flex flex-col items-center sekcja">
                    <div className="h-full w-full flex z-10">
                        <div className="h-full w-full bg-carport-img bg-no-repeat bg-cover bg-center flex z-10">
                            <Header
                                setIsMenuPopUpOpen={setIsMenuPopUpOpen}
                                isMenuPopUpOpen={isMenuPopUpOpen}
                            />
                        </div>
                    </div>
                </div>
            </Carousel>
        </section>
    );
}

export default CompetitionIntroduction;
