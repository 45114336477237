import React from "react";

function WinnerNameAndPriceCaption() {
  return (
    <div className="h-1/4 w-full sm:mb-5 lg:mb-0 bg-inherit flex flex-col">
      <div className="h-auto w-full bg-inherit flex flex-col">
        <div className="h-auto mt-10 mb-3 font-openSansLight font-semibold text-graphite">
          Main prize of € 3000
        </div>
        <div className="h-auto w-full flex flex-row">
          <div className="h-auto w-auto mr-6 font-openSansLight font-light text-graphite text-sm">
            Designer:
          </div>
          <div className="h-auto w-auto font-openSansLight font-light text-graphite text-sm">
            Arch. Adam Piaseczyński
          </div>
        </div>
        <div className="h-auto w-full mt-1 flex flex-row">
          <div className="h-auto w-auto mr-9 font-openSansLight font-light text-graphite text-sm">
            Project:
          </div>
          <div className="h-auto w-auto font-openSansLight font-light text-graphite text-sm">
            Wooden Eco-House in the Forest
          </div>
        </div>
      </div>
    </div>
  );
}

export default WinnerNameAndPriceCaption;
