import React from "react";

function YellowRhomb2021() {
  return (
    <div className="h-6 sm:h-full w-auto sm:w-1/3 lg:w-1/5 xl:w-1/3 bg-white flex items-center lg:items-start xl:items-center lg:justify-center xl:justify-center 2xl:justify-start">
      <div className="h-full sm:h-2/3 sm:w-1/3 lg:h-3/4 lg:w-full xl:h-2/3 xl:w-5/12 border border-solid border-yellow bg-yellow flex items-center justify-center font-openSansLight font-semibold text-black text-xl sm:text-2xl transform -skew-x-12">
        <div className="transform skew-x-12 text-graphite">2021</div>
      </div>
    </div>
  );
}

export default YellowRhomb2021;
