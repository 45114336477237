import React from "react";
import Facebook from "../../assets/sociale/icon-fb.svg";

function FacebookLogo() {
  return (
    <a
      href="https://www.facebook.com/sunroofab/"
      target="_blank"
      rel="noreferrer noopener"
    >
      <div className="h-auto w-auto">
        <img alt="" src={Facebook}></img>
      </div>
    </a>
  );
}

export default FacebookLogo;
