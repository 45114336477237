import React from "react";

function CompetitionBestSunRoofProjectCaption() {
  return (
    <div className="w-auto mr-2 sm:mr-5 xl:mr-0 xl:w-10/12 2xl:w-auto 2xl:mr-5 font-openSansSemiBold font-semibold text-graphite text-xl sm:text-4xl md:text-4xl lg:text-3xl xl:text-4xl">
      Best SunRoof Project
    </div>
  );
}

export default CompetitionBestSunRoofProjectCaption;
