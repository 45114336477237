import React from "react";

function JuryPhoto(props) {
  return (
    <div className="lg:h-full lg:w-full bg-inherit flex justify-center lg:justify-start hover:opacity-50">
      <img src={props.img} alt="">
      </img>
    </div>
  );
}

export default JuryPhoto;
