import React from "react";

function AboutCompetitionPDF() {
    return (
        <div className="h-auto w-full mt-7 bg-inherit flex flex-row items-center">
            <a
                href={process.env.PUBLIC_URL + "rules.pdf"}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer flex flex-row font-semibold underline"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                        clipRule="evenodd"
                    />
                </svg>
                <div className="ml-3">Rules of the competition (PDF)</div>
            </a>
        </div>
    );
}

export default AboutCompetitionPDF;
