import React from "react";
import SunRoofLogoWhite from "../atoms/SunRoofLogoWhite.js";
import CopyrightCaption from "../atoms/CopyrightCaption.js";
import FooterSunRoofCaption from "../atoms/FooterSunRoofCaption.js";
import FooterSocialMediaLogos from "../molecules/FooterSocialMediaLogos.js";

function FooterOrganism() {
  return (
    <div className="h-auto w-full bg-graphite flex justify-center">
      <div className="h-full lg:w-3/4 ml-5 mr-5 sm:ml-10 sm:mr-10 md:w-full md:pl-10 md:pr-10 xl:pl-20 pt-10 xl:pr-20 pb-10 bg-inherit flex flex-col items-center">
        <div className="h-1/3 w-1/3 md:h-1/6 md:w-1/6">
          <SunRoofLogoWhite />
        </div>
        <FooterSunRoofCaption />
        <FooterSocialMediaLogos />
        <CopyrightCaption />
      </div>
    </div>
  );
}

export default FooterOrganism;
