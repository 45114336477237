import React from "react";

function HonoraryPartnerCaption() {
  return(
    <div className="h-1/3 mt-4 sm:mt-0 sm:ml-2 lg:h-auto mb-4 w-full bg-inherit-300 flex font-openSansLight font-light text-graphite text-sm">
      PARTNERS
    </div>
  );
}

export default HonoraryPartnerCaption;