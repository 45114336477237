import React from "react";
import HonoraryPartnerCaption from "../atoms/HonoraryPartnerCaption";
import PartnerLogoTemplate from "../atoms/PartnerLogoTemplate.js";
import BIM from "../../assets/HonoraryPatronage/partner01.png";
import DanielCieslik from "../../assets/HonoraryPatronage/partner02.png";
import Grenton from "../../assets/HonoraryPatronage/partner03.png";
import ScandinavianPolish from "../../assets/HonoraryPatronage/partner04.png";
import Tengbom from "../../assets/HonoraryPatronage/partner05.png";
import Stowarzyszenie from "../../assets/HonoraryPatronage/partner06.png";

function HonoraryPatronagePartner() {
    return (
        <div className="h-auto sm:w-full lg:w-1/3 sm:mt-10 lg:mt-0 sm:mb-10 lg:mb-0 bg-inherit flex flex-col">
            <HonoraryPartnerCaption />
            <div className="flex flex-wrap content-start grid grid-cols-2">
                <PartnerLogoTemplate logo={BIM} href="https://www.bimobject.com/en" />
                <PartnerLogoTemplate logo={DanielCieslik} href="https://www.anta-architekci.pl/" />
                <PartnerLogoTemplate logo={Grenton} href="https://www.grenton.com/" />
                <PartnerLogoTemplate logo={Stowarzyszenie} href="https://www.sarp.pl/" />
                <PartnerLogoTemplate logo={ScandinavianPolish} href="https://www.spcc.pl/en/" />
                <PartnerLogoTemplate logo={Tengbom} href="https://tengbom.se/" />
            </div>
        </div>
    );
}

export default HonoraryPatronagePartner;
