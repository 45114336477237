import React from "react";

function FooterSunRoofCaption() {
  return (
    <div className="h-auto mt-5 mb-5 md:w-full lg:w-1/2 bg-inherit font-openSansLight font-light text-xs flex text-white text-center">
      SunRoof is the most efficient and environment-friendly solar roof on the
      market. Our underlying objective is to replace all roofs with SunRoofs
      that will produce enough electricity for people around the globe, ensuring
      a future where we can thrive together with nature.
      <br />
      <br />
      The revolutionary concept for solar roofs came to life in Sweden in 2013,
      based on the opportunities that solar technology offered at that time,
      coupled with the high-quality Swedish design. The functionality of our
      roofs sets new standards, as they protect, insulate and ventilate houses,
      and produce electricity at the same time. Integrated with the whole
      structure of the building, they are simple, lightweight, and easy to
      install thanks to novel and specially designed materials and fittings that
      entirely substitute multiple-layer roofs. SunRoof’s matching panels and
      trims make for a coherent and highly elegant structure. With the
      eye-catching modern look and the ultra-smooth solar panel surface, SunRoof
      is an innovative solution like no other.
    </div>
  );
}

export default FooterSunRoofCaption;
