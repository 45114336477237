import React from "react";
import CompetitionBestSunRoofProjectCaption from "../atoms/CompetitionBestSunRoofProjectCaption";
import CompetitionFutureHouseCaption from "../atoms/CompetitionFutureHouseCaption";
import CompetitionBIMobjectDesignCaption from "../atoms/CompetitionBIMobjectDesignCaption";
import YellowRhomb2021 from "../atoms/YellowRhomb2021.js"

function FutureHouseCompetitionSloganWithDescription() {
  return (
    <div className="h-full w-full pt-10 xl:pl-10 bg-white flex flex-col">
      <CompetitionFutureHouseCaption />
      <div className="h-1/3 w-full sm:mb-7 md:mb-0 bg-inherit flex flex-row xl:items-center lg:items-start">
        <CompetitionBestSunRoofProjectCaption />
        <YellowRhomb2021 />
      </div>
      <CompetitionBIMobjectDesignCaption />
    </div>
  );
}

export default FutureHouseCompetitionSloganWithDescription;
