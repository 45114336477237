import React from "react";
import HonoraryMediaPartnersCaption from "../atoms/HonoraryMediaPartnersCaption.js";
import PartnerLogoTemplate from "../atoms/PartnerLogoTemplate.js";
import ArchitekturaInfo from "../../assets/HonoraryPatronage/media01.png";
import Architektura from "../../assets/HonoraryPatronage/media02.png";
import Builder from "../../assets/HonoraryPatronage/media03.png";
import Extradom from "../../assets/HonoraryPatronage/media04.png";
import HouseCollection from "../../assets/HonoraryPatronage/media05.png";
import NowoczesnaStodola from "../../assets/HonoraryPatronage/media06.png";
import SztukaArchitektury from "../../assets/HonoraryPatronage/media07.png";

function HonoraryPatronageMediaPartners() {
    return (
        <div className="h-auto sm:w-full lg:w-1/3 sm:mt-10 lg:mt-0 sm:mb-10 lg:mb-0 bg-inherit flex flex-col">
            <HonoraryMediaPartnersCaption />
            <div className="flex flex-wrap content-start grid grid-cols-2">
                <PartnerLogoTemplate logo={ArchitekturaInfo} href="https://architektura.info/" />
                <PartnerLogoTemplate logo={Architektura} href="https://architektura.muratorplus.pl/" />
                <PartnerLogoTemplate logo={Builder} href="https://builderpolska.pl/" />
                <PartnerLogoTemplate logo={Extradom} href="https://www.extradom.pl/" />
                <PartnerLogoTemplate logo={HouseCollection} href="https://housecollection.pl/" />
                <PartnerLogoTemplate logo={NowoczesnaStodola} href="https://nowoczesnastodola.pl/" />
                <PartnerLogoTemplate logo={SztukaArchitektury} href="https://sztuka-architektury.pl/" />
            </div>
        </div>
    );
}

export default HonoraryPatronageMediaPartners;
