import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function JuryPersonSilhouette(props) {
  const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full md:max-w-full md:w-1/2 sm:p-6">
              <div className="flex flex-col xl:flex-row items-center justify-center">
                <div className="h-auto w-full xl:h-full xl:w-1/2">
                  <img src={props.img} alt="" />
                </div>
                <div className="h-auto w-full xl:h-full xl:w-1/2 xl:pl-10 flex">
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="h-auto w-full mb-2 text-2xl leading-6 font-semibold font-openSansLight text-graphite flex text-left">
                      {props.name}
                    </div>
                    <div className="h-auto w-full mb-4 text-lg leading-6 font-semibold font-openSansLight text-graphite flex text-left">
                      {props.title}
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-graphite text-left">
                        {props.text}
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="hidden xl:flex h-auto w-auto mr-6 mt-6 top-0 right-0 justify-center items-center fixed"
                    onClick={props.onClickItem}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="xl:hidden mt-5 sm:mt-6">
                <button
                  type="button"
                  className="font-openSansLight font-semibold inline-flex justify-center w-full border border-solid border-graphite shadow-sm px-4 py-2 bg-white text-base text-graphite focus:outline-none sm:text-sm"
                  onClick={props.onClickItem}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
