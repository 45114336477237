import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import One from "../../assets/2020MoonHouse/d1.png"
import Two from "../../assets/2020MoonHouse/d2.png"
import Three from "../../assets/2020MoonHouse/d3.png"
import Four from "../../assets/2020MoonHouse/d4.png"
import "./CarouselItemsStyling.css";

function DemoCarouselHonourablePrize() {
  return (
    <Carousel showThumbs={false} showStatus={false}>
      <div className="h-full xl:h-full w-full sm:mb-0 sm:w-full flex">
        <img
          src={One}
          alt=""
          className="h-full w-full mx-auto flex"
        />
      </div>
      <div className="h-auto xl:h-full w-full sm:mb-0 sm:w-full flex">
        <img
          src={Two}
          alt=""
          className="h-full w-full mx-auto flex"
        />
      </div>
      <div className="h-auto xl:h-full w-full sm:mb-0 sm:w-full flex">
        <img
          src={Three}
          alt=""
          className="h-full w-full mx-auto flex"
        />
      </div>
      <div className="h-auto xl:h-full w-full sm:mb-0 sm:w-full flex">
        <img
          src={Four}
          alt=""
          className="h-full w-full mx-auto flex"
        />
      </div>
    </Carousel>
  );
}

export default DemoCarouselHonourablePrize;