import React, { useState } from "react";
import ReadMoreHonourableMentionButton from "../organisms/HonourableMentionProjectDescription.js";
import { classNames } from "../../utils";

function HonourableMentionProjectDescription() {
  const [IsReadMoreOpen, SetIsReadMoreOpen] = useState(true);

  return (
    <div className="h-auto w-full bg-inherit flex flex-col font-openSansLight font-light text-graphite text-sm overflow-clip">
      Life surprises us every day! We are currently witnessing very hard times.
      This situation makes us all realize that the home should be a sanctuary
      and an oasis of peace and should adapt to changes in our lives. I believe
      that if our life changes, the home should change with it. He should adapt.
      This approach accompanied me during the first considerations about the
      project.
      <br />
      <br />
      Many intelligent systems have been used in the house, such as room
      temperature regulation due to the weather, or an electric car charging
      station located in the garage. Thanks to the large amount of natural
      material wood, the building is one with nature. A large number of glazing
      provides excellent access to natural light, and the location of the
      windows provides natural ventilation.
      <br />
      <br />
      <div className="flex flex-col" >
        <div className={classNames("", IsReadMoreOpen ? "hidden" : "flex")}>
          The variety of needs and changing requirements became the prelude to
          work. It is not difficult to imagine a situation where the house
          becomes too small as the family has grown. The modularity of the
          assumption...
        </div>
        {IsReadMoreOpen === true ? (
          <ReadMoreHonourableMentionButton
            isActive={IsReadMoreOpen}
            onClickItem={() => {
              SetIsReadMoreOpen(!IsReadMoreOpen);
            }}
          />
        ) : null}
        <button
          className="h-auto w-auto font-openSansLight font-semibold text-graphite flex underline"
          onClick={() => {
            SetIsReadMoreOpen(!IsReadMoreOpen);
          }}
        >
          {/*IsReadMoreOpen === true ? "Show less" : "Read more"*/}
        </button>
      </div>
    </div>
  );
}

export default HonourableMentionProjectDescription;
