import React from "react";

function PartnerLogoTemplate(props) {
    return (
        <a href={props.href} target="_blank" rel="noreferrer noopener">
            <img alt="" src={props.logo} className="w-full"></img>
        </a>
    );
}

export default PartnerLogoTemplate;
