import React from "react";

function AboutCompetitionCaption() {
  return (
    <div className="h-1/12 w-full pb-5 bg-inherit flex font-openSansLight font-semibold text-graphite text-2xl">
      About <div className="hidden lg:block lg:ml-2">competition</div>
    </div>
  );
}

export default AboutCompetitionCaption;
