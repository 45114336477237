import React from "react";
import FutureHouse from "../organisms/FutureHouse.js";
import AboutCompetitionOrganism from "../organisms/AboutCompetitionOrganism.js";
import JuryOrganism from "../organisms/JuryOrganism.js";
import WinnersOrganism from "../organisms/WinnersOrganism.js";
import ProjectSubmissionOrganism from "../organisms/ProjectSubmissionOrganism.js";
import FooterOrganism from "../organisms/FooterOrganism.js";
import CompetitionIntroduction from "../organisms/CompetitionIntroduction.js";
import PatronageOrganism from "../organisms/PatronageOrganism.js";
import FrontPageHamburgerMenuButton from "../atoms/FrontPageHamburgerMenuButton";

function MainPageTemplate({
    setIsMenuPopUpOpen,
    isMenuPopUpOpen,
    SetIsLogInModalOpen,
    IsLogInModalOpen,
    SetIsAccountBeingRegistered,
    isAccountBeingRegistered,
    SetIsUserLoggedIn,
    IsUserLoggedIn,
}) {
    return (
        <div>
            <FrontPageHamburgerMenuButton
                setIsMenuPopUpOpen={setIsMenuPopUpOpen}
                isMenuPopUpOpen={isMenuPopUpOpen}
            />
            <CompetitionIntroduction
                setIsMenuPopUpOpen={setIsMenuPopUpOpen}
                isMenuPopUpOpen={isMenuPopUpOpen}
            />
            <FutureHouse />
            <PatronageOrganism />
            <AboutCompetitionOrganism />
            <JuryOrganism />
            <WinnersOrganism />
            {
                /*
                !IsUserLoggedIn && (
                    <ProjectSubmissionOrganism
                        SetIsLogInModalOpen={SetIsLogInModalOpen}
                        IsLogInModalOpen={IsLogInModalOpen}
                        SetIsAccountBeingRegistered={SetIsAccountBeingRegistered}
                        isAccountBeingRegistered={isAccountBeingRegistered}
                        SetIsUserLoggedIn={SetIsUserLoggedIn}
                        IsUserLoggedIn={IsUserLoggedIn}
                    />
                )
                */
            }
            <FooterOrganism />
        </div>
    );
}

export default MainPageTemplate;
