import React from "react";
import HonoraryPatronageSweden from "../molecules/HonoraryPatronageSweden.js";
import HonoraryPatronagePartner from "../molecules/HonoraryPatronagePartner.js";
import HonoraryPatronageMediaPartners from "../molecules/HonoraryPatronageMediaPartners.js";

function PatronageOrganism() {
    return (
        <div className="w-full bg-grey flex justify-center">
            <div className="lg:w-3/4 w-full pl-5 pr-5 mb-6 sm:mb-0 sm:pl-10 sm:pr-10 lg:pl-0 lg:pr-0 xl:pl-20 pt-10 xl:pr-20 pb-10 bg-inherit flex lg:flex-row flex-wrap space-y-10 lg:space-y-0">
                <HonoraryPatronageSweden />
                <HonoraryPatronagePartner />
                <HonoraryPatronageMediaPartners />
            </div>
        </div>
    );
}

export default PatronageOrganism;
