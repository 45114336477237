import React from "react";
import SunRoofLogo from "../atoms/SunRoofLogo.js";

function FrontPageLogoGreyBackground() {
  return (
    <div className="h-1/3 sm:h-2/5 pl-2 pr-2 pb-6 sm:pl-2 sm:pr-2 md:pr-4 md:pl-4 lg:pb-8 lg:pl-5 lg:pr-5 bg-white flex justify-center items-end">
      <SunRoofLogo />
    </div>
  );
}

export default FrontPageLogoGreyBackground;
