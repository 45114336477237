import React from "react";

function JuryName(props) {
  return (
    <div className="h-auto w-auto sm:mt-2 font-openSansLight font-semibold text-graphite flex sm:justify-center lg:justify-start">
      {props.name}
    </div>
  );
}

export default JuryName;
