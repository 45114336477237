import React from "react";
import Twitter from "../../assets/sociale/icon-tt.svg";

function TwitterLogo() {
  return (
    <a href="https://twitter.com/LechKaniuk?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5E" target="_blank" rel="noreferrer noopener">
      <div className="h-auto w-auto">
        <img alt="" src={Twitter}></img>
      </div>
    </a>
  );
}

export default TwitterLogo;
