import React from "react";
import FutureHouseCompetitionSloganWithDescription from "../molecules/FutureHouseCompetitionSloganWithDescription.js";
import PrizePoolYellowCircle from "../atoms/PrizePoolYellowCircle.js";

function FutureHouse() {
  return (
    <div className="lg:h-52 xl:h-56 w-full bg-white flex justify-center">
      <div className="h-full bg-white flex relative lg:w-3/4 w-full sm:w-full lg:flex-row flex-col items-center sm:flex-col sm:items-center lg:bottom-32 md:bottom-0 z-10">
        <div className="h-full w-full lg:w-2/3 sm:w-full pl-5 pr-5 sm:pl-10 sm:pr-10 bg-inherit flex">
          <FutureHouseCompetitionSloganWithDescription />
        </div>
        <div className="h-full lg:w-1/3 w-full bg-inherit flex">
          <PrizePoolYellowCircle />
        </div>
      </div>
    </div>
  );
}

export default FutureHouse;
