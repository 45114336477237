import React from "react";
import Embassy from "../../assets/HonoraryPatronage/Embassy.png";

function EmbassyOfSwedenLogo() {
    return (
        <div className="pr-36">
            <a
                href="https://www.swedenabroad.se/en/about-sweden-non-swedish-citizens/#"
                target="_blank"
                rel="noreferrer noopener"
            >
                {" "}
                <img alt="" src={Embassy}></img>
            </a>
        </div>
    );
}

export default EmbassyOfSwedenLogo;
