import React from "react";

function CopyrightCaption() {
    return (
        <div className="h-1/12 w-auto mt-5 bg-inherit flex text-white">
            &copy; {new Date().getFullYear()}
        </div>
    );
}

export default CopyrightCaption;
