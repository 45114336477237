import React from "react";

function AboutCompetitionText() {
  return (
    <div className="h-5/6 w-full text-graphite font-openSansLight font-light">
      Trends in the world of architecture today are just proliferating. On the
      one hand, we are looking for ideas to build a house at the lowest possible
      cost, but on the other hand, we create great buildings that are impressive
      not only in terms of size, but also in form and materials they are made
      of.
      <br />
      <br />
      <b className="font-semibold">House of the Future</b> means the smart
      house. One that reacts to changing external conditions. House designed in
      a smart way makes use of the conditions in which it was created - wind,
      light, and natural heat, which, for example, is given back to the interior
      at night, and prevents excessive heating on hot days.
      <br />
      <br />
      <b className="font-semibold">House of the Future</b> means a zero-energy
      house. Using sustainable energy technology (Renewable Energy Sources). The
      house with a 2in1 SunRoof solar roof, which generates electricity thanks
      to solar energy, and also looks modern and is fully functional as a roof.
      <br />
      <br />
      <b className="font-semibold">Solar Carport</b> is a great solution when
      there is no possibility of making a photovoltaic roof or a photovoltaic
      facade. We gain access to an innovative solar charging system, generate
      our own energy, and become energy independent. The effective and
      functional solar carport makes driving a car, motorcycle or electric
      scooter free. The surplus energy can be used to power other electrical
      appliances in the home.
      <br />
      <br />
      <b className="font-semibold">
        And what is your House or Carport of the Future?
      </b>
      <br />
      <br />
      Take part in an international competition:{" "}
      <b className="font-semibold">
        Design of the Future. Best SunRoof Project 2021. Create the House or
        Carport of the Future with a SunRoof solar roof using BIMobject and
        Grenton Smart Home System.
      </b>
      <br />
      <br />
      Competition participants may be professional architects, graduates of
      architectural faculties, students of the last year of engineering /
      bachelor studies (1st cycle degree studies), students of master's studies
      (2-year 2nd cycle degree master's studies).
      <br />
      <br />
      Join the <b className="font-semibold">world of SunRoof.</b>
      <br />
      Show us your vision of the{" "}
      <b className="font-semibold">Design of the Future!</b>
    </div>
  );
}

export default AboutCompetitionText;
