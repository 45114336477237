import React from "react";
import JuryOfCompetitionCaption from "../atoms/JuryOfCompetitionCaption.js";
import JuryPersonMolecule from "../molecules/JuryPersonMolecule.js";
import LechKaniukThumb from "../../assets/Portrait/LechKaniukThumb.jpg";
import LechKaniukPop from "../../assets/Portrait/LechKaniukPop.jpg";
import StefanGullgrenThumb from "../../assets/Portrait/StefanGullgrenThumb.jpg";
import StefanGullgrenPop from "../../assets/Portrait/StefanGullgrenPop.jpg";
import MichalDercThumb from "../../assets/Portrait/MichalDercThumb.jpg";
import MichalDercPop from "../../assets/Portrait/MichalDercPop.jpg";
import BartlomiejGierszynThumb from "../../assets/Portrait/BartlomiejGierszynThumb.jpg";
import BartlomiejGierszynPop from "../../assets/Portrait/BartlomiejGierszynPop.jpg";
import IvanaKildsgaardThumb from "../../assets/Portrait/IvanaKildsgaardThumb.jpg";
import IvanaKildsgaardPop from "../../assets/Portrait/IvanaKildsgaardPop.jpg";
import AgnieszkaZielinskaThumb from "../../assets/Portrait/AgnieszkaZielinskaThumb.jpg";
import AgnieszkaZielinskaPop from "../../assets/Portrait/AgnieszkaZielinskaPop.jpg";
import DanielCieslikThumb from "../../assets/Portrait/DanielCieslikThumb.jpg";
import DanielCieslikPop from "../../assets/Portrait/DanielCieslikPop.jpg";
import NataliaChylinskaThumb from "../../assets/Portrait/NataliaChylinskaThumb.jpg";
import NataliaChylinskaPop from "../../assets/Portrait/NataliaChylinskaPop.jpg";
import WeronikaKoniorThumb from "../../assets/Portrait/WeronikaKoniorThumb.jpg";
import WeronikaKoniorPop from "../../assets/Portrait/WeronikaKoniorPop.jpg";
import WojciechRynkowskiThumb from "../../assets/Portrait/WojciechRynkowskiThumb.jpg";
import WojciechRynkowskiPop from "../../assets/Portrait/WojciechRynkowskiPop.jpg";
import JacekJakubowskiThumb from "../../assets/Portrait/JacekJakubowskiThumb.jpg";
import JacekJakubowskiPop from "../../assets/Portrait/JacekJakubowskiPop.jpg";
import IreneuszPolaczekThumb from "../../assets/Portrait/IreneuszPolaczekThumb.jpg";
import IreneuszPolaczekPop from "../../assets/Portrait/IreneuszPolaczekPop.jpg";
import KrzysztofSoloduchaThumb from "../../assets/Portrait/KrzysztofSoloduchaThumb.jpg";
import KrzysztofSoloduchaPop from "../../assets/Portrait/KrzysztofSoloduchaPop.jpg";
import MichalPierzchalskiThumb from "../../assets/Portrait/MichalPierzchalskiThumb.jpg";
import MichalPierzchalskiPop from "../../assets/Portrait/MichalPierzchalskiPop.jpg";

function JuryOrganism() {
  return (
    <div className="h-min-full w-full bg-grey flex justify-center">
      <div
        id="JuryOfCompetition"
        className="h-auto lg:w-3/4 sm:w-full pl-5 pr-5 sm:pl-10 sm:pr-10 lg:pl-0 lg:pr-0 xl:pl-20 pt-10 xl:pr-20 pb-10 bg-inherit flex flex-col"
      >
        <JuryOfCompetitionCaption />
        <div className="h-auto w-full grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 sm:col-span-8 md:col-span-4 lg:col-span-4 justify-items-center gap-2.5">
          <JuryPersonMolecule
            name={"Lech Kaniuk"}
            title={"SunRoof"}
            thumbImg={LechKaniukThumb}
            popImg={LechKaniukPop}
            text={
              "CEO of SunRoof. An experienced manager, serial entrepreneur and business scaling expert. He has managed or supervised a portfolio of companies that operate in the fields of modern technology, finance, real estate, photovoltaics and energy. He has co-authored the success of brands: Delivery Hero, PizzaPortal, iTaxi, Bean & Buddies, ePark, Heimdal, Nidavellir, JLS Property, Dropbybox.com, Prowly.com and Migam.org."
            }
          />
          <JuryPersonMolecule
            name={"Stefan Gullgren"}
            title={"Ambassador of Sweden to Poland"}
            thumbImg={StefanGullgrenThumb}
            popImg={StefanGullgrenPop}
            text={
              <div>
                Ambassador of Sweden to Poland since September 2017. He served
                as Deputy Director-General and Head of Department for Eastern
                Europe and Central Asia in the Ministry for Foreign Affairs of
                Sweden 2013-2017, and as Ambassador of Sweden to Ukraine
                2009-2013. <br />
                <br /> Photo: Kristian Pohl
              </div>
            }
          />
          <JuryPersonMolecule
            name={"Michał Derc"}
            title={"BIMobject"}
            thumbImg={MichalDercThumb}
            popImg={MichalDercPop}
            text={
              "Team Manager at BIMobject. A graduate of Architecture at the Poznań University of Technology and the University of Stuttgart. In his professional work he dealt with, inter alia, developing concepts and designs for both public buildings and residential buildings. At BIMobject, he and his team create, supervise and verify Revit family models for the company's key clients. He is also responsible for managing and planning Content Developers activities as well as project coordination."
            }
          />
          <JuryPersonMolecule
            name={"Bartłomiej Gierszyn"}
            title={"Grenton"}
            thumbImg={BartlomiejGierszynThumb}
            popImg={BartlomiejGierszynPop}
            text={
              "Head of Marketing in Grenton. Fully involved into setting up & development of Grenton’s strategy towards the leading, global smart home system provider. A passionate Marketing Executive with more than 20-year experience in moving concepts into go2market successes at the leading companies (incl. T-Mobile - Deutsche Telecom Group, Netia & others). Passionate about new technologies, smart living trends & always personalized way of life."
            }
          />
          <JuryPersonMolecule
            name={"Ivana Kildsgaard"}
            title={"Tengbom"}
            thumbImg={IvanaKildsgaardThumb}
            popImg={IvanaKildsgaardPop}
            text={
              "Partner & Director of Sustainability and Quality at Tengbom. Passionate about innovation and business development, finding drivers and enablers for improving sustainability and circularity in architectural practice. Member of the scientific committees for Nordic Passive House Conferences, since 2019 called Zero Emission and Plus Energy Buildings and Neighbourhoods. Awarded “Preventer of the Year” (2018) for the effect that circular solutions for interiors can have on reducing resource use and climate impact."
            }
          />
          <JuryPersonMolecule
            name={"Agnieszka Zielińska"}
            title={"Scandinavian-Polish Chamber Of Commerce"}
            thumbImg={AgnieszkaZielinskaThumb}
            popImg={AgnieszkaZielinskaPop}
            text={
              "Managing Director Scandinavian-Polish Chamber of Commerce. Agnieszka Zielińska has +15 years of extensive experience in the international business environment in many areas: business relations, marketing, events management. She is also a business trainer and a lecturer. She has worked in a wide array of industries including real estate, media, business NGO and is committed, for more than 10 years, to developing Scandinavian – Polish cooperation. Graduated from Wrocław University, WSB University (HR) and SET Academy (business trainer)."
            }
          />
          <JuryPersonMolecule
            name={"Daniel Cieślik"}
            title={"Anta Architekci studio"}
            thumbImg={DanielCieslikThumb}
            popImg={DanielCieslikPop}
            text={
              "A graduate of the Faculty of Architecture of the Cracow University of Technology (2001). In the same year, he obtained an international diploma from the Royal Institute of British Architects (RIBA). He is the founder of the ANTA ARCHITEKCI studio, winner of many industry awards, and privately a dad and a golf and automotive enthusiast. ANTA ARCHITEKCI is an original studio established in 2003. Over the course of 18 years, it has gained the recognition of investors, and thanks to their trust and commitment of a great team of architects, it has completed nearly a thousand projects. The team consists of 26 architects and industry specialists in the field of construction and installation."
            }
          />
          <JuryPersonMolecule
            name={"Natalia Chylińska"}
            title={"Builder Polska"}
            thumbImg={NataliaChylinskaThumb}
            popImg={NataliaChylinskaPop}
            text={
              "Graduate of MA studies in the field of: Polish philology of the University of Nicolaus Copernicus in Toruń. In Builder she focuses on the section of Architecture & Design. Devotes a lot of attention to prestigious investments presented within the editions of the Builder's Library - Builder Exclusive. Photography is her greatest passion. She is constantly striving for self-development, so she gained an education in the field of a professional photographer and phototechnician. Her portfolio includes some interesting architectural photoshoots."
            }
          />
          <JuryPersonMolecule
            name={"Weronika Konior"}
            title={"Architektura-murator"}
            thumbImg={WeronikaKoniorThumb}
            popImg={WeronikaKoniorPop}
            text={
              'Architect, graduate of the Faculty of Architecture of the Cracow University of Technology and the Universitat Politecnica de Valencia. From 2019, photo editor and editor for student affairs in the leading monthly about architecture "Architektura-murator", secretary of the 9th edition of the nationwide competition for the most significant architectural projects in Poland - Życie w Architekturze (Living in Architecture).'
            }
          />
          <JuryPersonMolecule
            name={"Wojciech Rynkowski"}
            title={"Extradom"}
            thumbImg={WojciechRynkowskiThumb}
            popImg={WojciechRynkowskiPop}
            text={
              "Expert on the single-family housing market at Extradom.pl. He has been involved in the financial industry, and then in the single-family construction industry for over 25 years. For several years he has been responsible for creating the catalog and other Extradom publications. At that time, he was also involved in cooperation with studios in obtaining and updating content on the Extradom.pl website. Currently, apart from the catalog, he creates articles and materials in the expert section, cooperates with studios and external companies in their preparation, and takes part in numerous events and meetings."
            }
          />
          <JuryPersonMolecule
            name={"Ireneusz Polaczek"}
            title={"nowoczesna STODOŁA"}
            thumbImg={IreneuszPolaczekThumb}
            popImg={IreneuszPolaczekPop}
            text={
              <div>
                nowoczesna STODOŁA is what we call a combination of traditional
                architecture with modern and ecological thinking about
                construction, which is why it has become an excellent model of
                passive construction in Poland.<br /> nowoczesna STODOŁA refers to the
                archetype of the building in a form derived from the local
                tradition, consisting of four walls covered with a gable roof.
                Thanks to the simple and therefore compact shape, nowoczesna
                STODOŁA lets to decrease the construction and maintenance costs
                than for houses with the same area but a different shape.
                Traditional form imitated by nowoczesna STODOŁA is a nod to
                simplicity, which always remains a popular trend. It is so
                universal that it fits perfectly into any context and both in
                urban, suburban and rural spaces.
              </div>
            }
          />
          <JuryPersonMolecule
            name={"Jacek Jakubowski"}
            title={"HouseCollection"}
            thumbImg={JacekJakubowskiThumb}
            popImg={JacekJakubowskiPop}
            text={
              "Electrical engineer. For over 10 years, he has been a co-creator of project documentation, including single and multi-family buildings. He also specializes in designing teletechnical installations and building automation BMS. Passionate about energy-saving and modern solutions for minimalist architecture."
            }
          />
          <JuryPersonMolecule
            name={"Krzysztof Sołoducha"}
            title={"Sztuka Architektury"}
            thumbImg={KrzysztofSoloduchaThumb}
            popImg={KrzysztofSoloduchaPop}
            text={
              "Editor-in-chief of the Sztuka Architektury Group. Ph.D. of philosophy, lecturer in philosophy and marketing communication. Associate professor at the Department of Humanities of the Military University of Technology in Warsaw. A scientific editor and co-author of an anthology of texts in the field of the philosophy of computer science (2018), as well as monographs in the field of marketing communication theory and philosophy of architecture."
            }
          />
          <JuryPersonMolecule
            name={"Michał Pierzchalski"}
            title={"Architektura.info"}
            thumbImg={MichalPierzchalskiThumb}
            popImg={MichalPierzchalskiPop}
            text={
              "Ph.D. of Architecture, editor-in-chief of the Architektura.info service, lecturer at the Faculty of Architecture at the Warsaw University of Technology, running the studio of energy-saving houses operating within the National Energy Conservation Agency (Krajowa Agencja Poszanowania Energii S.A.)."
            }
          />
        </div>
      </div>
    </div>
  );
}

export default JuryOrganism;
