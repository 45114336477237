import React from "react";
import WinnerNameAndPriceCaption from "../atoms/WinnerNameAndPriceCaption.js";
import WinnerProjectDescription from "../atoms/WinnerProjectDescription.js";
import MainPrizeWinnerPhotos from "../molecules/MainPrizeWinnerPhotos.js";
import HonourableMentionNameAndPriceCaption from "../atoms/HonourableMentionNameAndPriceCaption.js";
import HonourableMentionProjectDescription from "../atoms/HonourableMentionProjectDescription.js";
import HonourableMentionPhotos from "../molecules/HonourableMentionPhotos.js";
import DemoCarouselMainPrize from "../molecules/CarouselItemMainPrizePhotos.js";
import DemoCarouselHonourablePrize from "../molecules/CarouselItemHonourablePrizePhotos.js";

function Winners2020() {
    return (
        <div className="flex flex-col">
            <div className="h-full w-full bg-inherit flex flex-col lg:flex-row items-center">
                <div className="h-full sm:w-full lg:w-5/12 bg-inherit flex flex-col">
                    <WinnerNameAndPriceCaption />
                    <div className="h-auto sm:h-3/4 mb-4 sm:mb-0 mt-5 w-full bg-inherit flex">
                        <WinnerProjectDescription />
                    </div>
                </div>
                <div className="lg:h-1/2 xl:h-full sm:w-full sm:mt-4 md:mt-0 lg:w-7/12 bg-inherit flex flex-col">
                    <div className="h-full w-full lg:hidden">
                        <DemoCarouselMainPrize />
                    </div>
                    <div className="hidden lg:flex">
                        <MainPrizeWinnerPhotos />
                    </div>
                </div>
            </div>
            <div className="h-full w-full bg-inherit flex flex-col lg:flex-row items-center">
                <div className="h-full sm:w-full lg:w-5/12 bg-inherit flex flex-col">
                    <HonourableMentionNameAndPriceCaption />
                    <div className="h-auto mt-5 mb-4 sm:mb-0 w-full bg-inherit flex">
                        <HonourableMentionProjectDescription />
                    </div>
                </div>
                <div className="lg:h-1/2 xl:h-full sm:w-full sm:mt-4 md:mt-0 lg:w-7/12 bg-inherit flex flex-col">
                    <div className="h-auto w-full lg:hidden">
                        <DemoCarouselHonourablePrize />
                    </div>
                    <div className="hidden lg:flex">
                        <HonourableMentionPhotos />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Winners2020;
