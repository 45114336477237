import React from "react";

function WinnerProjectDescription() {
  return (
    <div className="h-auto w-full bg-inherit flex font-openSansLight font-light text-graphite text-sm">
      The designed House of the Future is located near a beautiful forest so
      that its users can have the closest possible contact with nature on a
      daily basis. The design refers to the 'Modern Barn' and to the modernist
      principles of penetrating the interior with the exterior. The construction
      of a wooden frame and the use of wooden panels on the facade refer to the
      use of ecological materials constituting the core of the structure and the
      finish of the facade. Large glazing allows you to blur the boundary
      between the inside and outside. The simple shape fits the sparse style of
      the building and the aesthetic structure.
      <br />
      <br />
      The SunRoof 2in1 roof covers the entire roof surface, so we can maximize
      clean electricity production and use the accumulation system for later
      use. Thanks to this solution, it is possible to supply the house with full
      electricity and to have a home electric car charging station, which is
      profitable and environmentally friendly. In the house design, you can also
      see hidden gutters that collect rainwater for later use and the use of
      non-threshold patio doors so that disabled people and children can use it
      comfortably.
    </div>
  );
}

export default WinnerProjectDescription;
