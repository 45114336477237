import React from "react";

function HonoraryPatronageCaption() {
  return(
    <div className="h-1/3 lg:h-auto mb-4 w-full bg-inherit-300 flex font-openSansLight font-light text-graphite text-sm">
      HONORARY PATRONAGE
    </div>
  );
}

export default HonoraryPatronageCaption;