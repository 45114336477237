import React from "react";
import FacebookLogo from "../atoms/FacebookLogo.js";
import LinkedInLogo from "../atoms/LinkedInLogo.js";
import InstagramLogo from "../atoms/InstagramLogo.js";
import YoutubeLogo from "../atoms/YoutubeLogo.js";
import TwitterLogo from "../atoms/TwitterLogo.js";

function FooterSocialMediaLogos() {
  return (
    <div className="h-1/5 w-full bg-inherit flex text-white items-center justify-center">
      <div className="h-full w-5/6 sm:w-3/4 sm:mt-5 lg:w-1/3 bg-inherit flex flex-row items-center justify-around">
        <FacebookLogo />
        <LinkedInLogo />
        <InstagramLogo />
        <YoutubeLogo />
        <TwitterLogo />
      </div>
    </div>
  );
}

export default FooterSocialMediaLogos;
