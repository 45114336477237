import React from "react"; 
import { HashLink } from 'react-router-hash-link';

function MenuLinkOption(props) {
    return (
        <div className="h-full w-full pl-3 bg-inherit flex justify-start items-center cursor-pointer font-openSans font-light text-graphite text-3xl hover:bg-grey">
            <HashLink smooth to={props.href} onClick={props.onClick} className="h-full w-full flex items-center hover:font-semibold">
                {props.text}
            </HashLink>
        </div>
    );
}

export default MenuLinkOption;
