import React from "react";
import HonoraryPatronageCaption from "../atoms/HonoraryPatronageCaption.js";
import EmbassyOfSwedenLogo from "../atoms/EmbassyOfSwedenLogo.js";

function HonoraryPatronageSweden() {
    return (
        <div className="h-auto sm:w-full lg:w-1/3 sm:mt-10 lg:mt-0 sm:mb-10 lg:mb-0 bg-inherit flex flex-col">
            <HonoraryPatronageCaption />
            <EmbassyOfSwedenLogo />
        </div>
    );
}

export default HonoraryPatronageSweden;