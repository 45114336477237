import React from "react";
import One from "../../assets/2020WoodenEco/1.png"
import Two from "../../assets/2020WoodenEco/2.png"
import Three from "../../assets/2020WoodenEco/3.png"
import Four from "../../assets/2020WoodenEco/4.png"

function MainPrizeWinnerPhotos() {
  return (
    <div className="h-full w-full lg:pl-6 2xl:pt-20 xl:pt-24 lg:pt-4 bg-inherit flex flex-row flex-wrap justify-evenly sm:justify-center lg:items-end">
      <div className="h-auto xl:h-1/2 w-5/12 sm:w-1/2 sm:pr-2 sm:pb-2 flex">
        <img alt="" style={{ width: "100%" }} src={One}></img>
      </div>
      <div className="h-auto xl:h-1/2 w-5/12 sm:w-1/2 sm:pr-2 sm:pb-2 flex">
        <img alt="" style={{ width: "100%" }} src={Two}></img>
      </div>
      <div className="h-auto xl:h-1/2 w-5/12 sm:w-1/2 sm:pr-2 sm:pb-2 flex">
        <img alt="" style={{ width: "100%" }} src={Three}></img>
      </div>
      <div className="h-auto xl:h-1/2 w-5/12 sm:w-1/2 sm:pr-2 sm:pb-2 flex">
        <img alt="" style={{ width: "100%" }} src={Four}></img>
      </div>
    </div>
  );
}

export default MainPrizeWinnerPhotos;
