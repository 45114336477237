import React, { useState } from "react";
import WinnersCaption from "../atoms/WinnersCaption.js";
import WinnersYearMolecule from "../molecules/WinnerYearMolecule.js";
import Winners2020 from "./Winners2020.js";
import Winners2021 from "./Winners2021.js";

function WinnersOrganism() {

    const [edition, setEdition] = useState("2021");

    return (
        <div className="min-h-full w-full bg-white flex justify-center">
            <div
                id="Winners"
                className="h-auto lg:w-3/4 sm:w-full pl-5 pr-5 sm:pl-10 sm:pr-10 lg:pl-0 lg:pr-0 xl:pl-20 pt-10 xl:pr-20 pb-10 bg-inherit flex flex-col"
            >
                <WinnersCaption />
                <div className="mt-4">
                    <WinnersYearMolecule edition={edition} setEdition={setEdition} />
                </div>
                {
                    edition === "2020" ? (
                        <Winners2020 />
                    ) : (
                        <Winners2021 />
                    )
                }
            </div>
        </div>
    );
}

export default WinnersOrganism;
