import React from "react";
import SubmissionFormInputsAndButton from "../molecules/SubmissionFormInputsAndButton.js";
import ProjectSubmissionFutureHouseCaption from "../atoms/ProjectSubmissionFutureHouseCaption.js";
import ProjectSubmissionJoinUsCaption from "../atoms/ProjectSubmissionJoinUsCaption.js";
import ProjectSubmissionFourSteps from "../molecules/ProjectSubmissionFourSteps.js";
import AlreadyHaveAnAccountButton from "../atoms/AlreadyHaveAnAccountButton.js";

function ProjectSubmissionOrganism({
  SetIsLogInModalOpen,
  IsLogInModalOpen,
  SetIsAccountBeingRegistered,
  isAccountBeingRegistered,
  SetIsUserLoggedIn,
  IsUserLoggedIn,
}) {
  return (
    <div className="min-h-full w-full bg-white shadow-2xl flex justify-center">
      <div
        id="CreateAccount"
        className="min-h-full lg:w-3/4 md:w-full xl:pl-20 pt-10 xl:pr-20 lg:pb-20 bg-inherit flex flex-col justify-between"
      >
        <div className="min-h-full w-full mb-4 hidden lg:flex flex-row justify-between">
          <div className="h-full w-5/12 flex flex-col">
            <ProjectSubmissionFutureHouseCaption />
            <ProjectSubmissionJoinUsCaption />
            <SubmissionFormInputsAndButton
              SetIsAccountBeingRegistered={SetIsAccountBeingRegistered}
              isAccountBeingRegistered={isAccountBeingRegistered}
              SetIsUserLoggedIn={SetIsUserLoggedIn}
              IsUserLoggedIn={IsUserLoggedIn}
            />
            <AlreadyHaveAnAccountButton
              SetIsLogInModalOpen={SetIsLogInModalOpen}
              IsLogInModalOpen={IsLogInModalOpen}
            />
          </div>
          <div className="h-full w-5/12 2xl:mr-2">
            <ProjectSubmissionFourSteps />
          </div>
        </div>
        <div className="flex lg:hidden sm:min-h-full sm:w-full bg-inherit pl-5 sm:pl-10 pr-5 sm:pr-10 pb-10 sm:pb-20 flex-col">
          <ProjectSubmissionFutureHouseCaption />
          <ProjectSubmissionJoinUsCaption />
          <ProjectSubmissionFourSteps />
          <SubmissionFormInputsAndButton
            SetIsAccountBeingRegistered={SetIsAccountBeingRegistered}
            isAccountBeingRegistered={isAccountBeingRegistered}
            SetIsUserLoggedIn={SetIsUserLoggedIn}
            IsUserLoggedIn={IsUserLoggedIn}
          />
          <AlreadyHaveAnAccountButton
            SetIsLogInModalOpen={SetIsLogInModalOpen}
            IsLogInModalOpen={IsLogInModalOpen}
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectSubmissionOrganism;
