import React, { useState } from "react";
import JuryPhoto from "../atoms/JuryPhoto.js";
import JuryName from "../atoms/JuryName.js";
import JuryTitle from "../atoms/JuryTitle.js";
import JuryPersonSilhouette from "../atoms/JuryPersonSilhouette.js";

function JuryPersonMolecule(props) {
  const [IsPersonSilhouetteActive, SetIsPersonSilhouetteActive] =
    useState(false);

  return (
    <div
      onClick={() => {
        SetIsPersonSilhouetteActive(!IsPersonSilhouetteActive);
      }}
      className="h-auto sm:h-auto w-full sm:w-full flex flex-col mb-4 justify-center cursor-pointer"
    >
      {IsPersonSilhouetteActive === true ? (
        <JuryPersonSilhouette
          isActive={IsPersonSilhouetteActive}
          onClickItem={() => {
            SetIsPersonSilhouetteActive(!IsPersonSilhouetteActive);
          }}
          name={props.name}
          img={props.popImg}
          title={props.title}
          text={props.text}
        />
      ) : null}
      <div className="sm:h-3/4 w-full lg:h-full 2xl:w-full flex xl:items-start lg:items-end">
        <JuryPhoto img={props.thumbImg} />
      </div>
      <div className="h-24 w-full lg:w-full flex flex-col pt-4">
        <JuryName name={props.name} />
        <JuryTitle title={props.title} />
        <br />
        <br />
      </div>
    </div>
  );
}

export default JuryPersonMolecule;
