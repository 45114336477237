import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import ImgHouseFirst01 from '../../assets/2021Winners/HouseOfTheFuture/First/SunRoof_Konkurs_House_1_miejsce-1.jpg';
import ImgHouseFirst02 from '../../assets/2021Winners/HouseOfTheFuture/First/SunRoof_Konkurs_House_1_miejsce-2.jpg';
import ImgHouseFirst03 from '../../assets/2021Winners/HouseOfTheFuture/First/SunRoof_Konkurs_House_1_miejsce-3.jpg';
import ImgHouseFirst04 from '../../assets/2021Winners/HouseOfTheFuture/First/SunRoof_Konkurs_House_1_miejsce-4.jpg';

import ImgHouseHonor01 from '../../assets/2021Winners/HouseOfTheFuture/Honourable/SunRoof_Konkurs_House_wyroznienie-1.jpg';
import ImgHouseHonor02 from '../../assets/2021Winners/HouseOfTheFuture/Honourable/SunRoof_Konkurs_House_wyroznienie-2.jpg';
import ImgHouseHonor03 from '../../assets/2021Winners/HouseOfTheFuture/Honourable/SunRoof_Konkurs_House_wyroznienie-3.jpg';
import ImgHouseHonor04 from '../../assets/2021Winners/HouseOfTheFuture/Honourable/SunRoof_Konkurs_House_wyroznienie-4.jpg';

import ImgCarportFirst01 from '../../assets/2021Winners/CarportOfTheFuture/First/SunRoof_Konkurs_Carport_1_miejsce-1.jpg';
import ImgCarportFirst02 from '../../assets/2021Winners/CarportOfTheFuture/First/SunRoof_Konkurs_Carport_1_miejsce-2.jpg';
import ImgCarportFirst03 from '../../assets/2021Winners/CarportOfTheFuture/First/SunRoof_Konkurs_Carport_1_miejsce-3.jpg';
import ImgCarportFirst04 from '../../assets/2021Winners/CarportOfTheFuture/First/SunRoof_Konkurs_Carport_1_miejsce-4.jpg';

import ImgCarportHonor01 from '../../assets/2021Winners/CarportOfTheFuture/Honourable/SunRoof_Konkurs_Carport_wyroznienie-1.jpg';
import ImgCarportHonor02 from '../../assets/2021Winners/CarportOfTheFuture/Honourable/SunRoof_Konkurs_Carport_wyroznienie-2.jpg';
import ImgCarportHonor03 from '../../assets/2021Winners/CarportOfTheFuture/Honourable/SunRoof_Konkurs_Carport_wyroznienie-3.jpg';
import ImgCarportHonor04 from '../../assets/2021Winners/CarportOfTheFuture/Honourable/SunRoof_Konkurs_Carport_wyroznienie-4.jpg';

function Winners2021() {

    const winners = [
        {
            category: "House of the Future",
            prize: "Main prize of € 4000 and 3-hour consultations with BIMobject specialists on a selected topic",
            designer: "Arch. Dominik Gomółka",
            project: "Resilient House",
            lines: [
                "What is a House, what is the Future? House - a shelter, place to regenerate and foster, to protect and nurture. Future on the other hand - uncertainty, challenge, opportunity, and risk. Thus, a proposal for the innovative House of the Future investigates the theme of Resilience to tackle the challenges of the future, concentrating on the issues of energetic self-sufficiency, new lifestyles and resisting the effects of climate change.",
                "Designed house utilizes innovative technologies but does not strain from traditional and passive methods of providing self-sufficient energy, comfortable environment, and cost-efficient living for its dwellers. Project uses natural building materials: both for the structure of timber frame prefabricated walls and roof and its cladding - where charred wood provides natural and long-lasting shielding from the elements.",
                "The main actor in the play is the roof - optimized in shape to catch sun in its European location, its southern slope is almost entirely covered with the photovoltaic plane - almost seamlessly joint of smooth, dark faceted. Two lines of skylights cut the roof on opposite sides, creating a ventilation openings on east and west of the structure. The form is stark, minimal, and elegant - balanced using dark, warm wood and natural stone - low where the contact with the texture of the materials is frequent.",
                "The life within the building manifests in various openings hinting from underneath a dominant roof. Openings vary between each other, matching the function they serve and direction of the world they’re facing. Narrow crevices on the north, providing just a slight opening for the light- contrast with the vast openings on the south - where the large, sheltered terrace and a glazed wintergarden.",
            ],
            images: [
                ImgHouseFirst01,
                ImgHouseFirst02,
                ImgHouseFirst03,
                ImgHouseFirst04,
            ]
        },
        {
            category: "House of the Future",
            prize: "Honourable mention prize of € 500",
            designer: "Arch. Talal Ammouri",
            project: "The House Of The Future",
            lines: [
                "The idea behind this proposal challenges the form of the traditional roof that we commonly see across all architectural styles. The use of photovoltaic roofs for solar energy harvesting is a relatively new concept, yet the traditional sharp sloped roof has remained idle for centuries. This proposal for a net positive energy 4 bed 3.5 bath home reimagines roof design by leveraging AI technology.",
                "The traditional roof is designed with high grade slopes for optimal rainwater shed, so the concept behind this design exploration roots from the challenge to find the optimal orientation of a roof that would maximize solar harvesting based on the site’s specific geolocation, rather than optimizing rainwater shed. The House of the Future requires a futuristic and radical approach to design, which serves as the ethos behind this proposal.",
                "Liberating the traditional roof from its centuries old design begins by first flattening the roof planes and manipulating them from their center points. The planes’ orientations are then parametrized by introducing three rotation determining parameters in the XY, XZ, and YZ dimensional axes.",
                "The three parameters control both roof planes’ orientations, which are mirrored, and the goals are set to maximize solar energy harvesting by finding the best set of parameters that would theoretically capture the most solar radiation on the planes as well as cumulative sunlight hours. The parameters and the goals are established by setting up radiation and sunlight hour analyses, that then feed into an artificial intelligence solver (genetic algorithm) that outputs the optimized orientation after thousands of iterations.",
                "The hypothetical site is based on environmental data from Warsaw, Poland. After several hundred thousand iterations, and some design experimentations that consisted of 3 and 4 identical roof planes with the same area coverage rather than the traditional 2 planed roof, the ideal set of parameters that theoretically captures the most solar radiation in kWh and sunlight hours consists of two planes. They are rotated 15 degrees in the XY plane, 15 degrees in the XZ plane, and 11 degrees in the YZ plane.",
                "Against an average of several benchmarks based on the traditional roof design (with the area remaining constant across all iterations), the optimized iteration captures 20% more solar radiation in kWh, 49% more sunlight hours, and is net positive energy, validating its title as the House of the Future.",
            ],
            images: [
                ImgHouseHonor01,
                ImgHouseHonor02,
                ImgHouseHonor03,
                ImgHouseHonor04,
            ]
        },
        {
            category: "Carport of the Future",
            prize: "Main prize of € 2000",
            designer: "Arch. Miłosz Mleczko",
            project: "Versatile Carport",
            lines: [
                "Natural design of the carport it is matched to the greenery of the garden, which makes it suitable for houses of various styles. A combination of a modern form using the SunRoof system with natural recycled materials makes the carport also suitable for a historical spaces and buildings. Universal design inspired by nature makes the carport fit for rural and natural spaces.",
                "Mono-pitched roof with the most favorable roof pitch (30°) was exposed on the south side. This makes the 55 square meter SunRoof generate the highest possible profit.",
                "The carport provides electricity for the car and satisfies half of household energy demands.",
                "It was assumed that with the proposed sun exposure and angle, 55square meter SunRoof will provide approximately 9000 kWh per year. If the electric car will use 4000 kWh p.a., carport will generate electricity surplus of 5000 kWh per year. It is a half of medium household energy demands.",
                "This means that two such carport can provide electricity for two electric cars and the entire household!",
            ],
            images: [
                ImgCarportFirst01,
                ImgCarportFirst02,
                ImgCarportFirst03,
                ImgCarportFirst04,
            ]
        },
        {
            category: "Carport of the Future",
            prize: "Honourable mention prize of € 500",
            designer: "Arch. Iga Soczawa",
            project: "Carport Pro",
            lines: [
                "Feel the exceptional power with Carport Pro! Its geometric shape hides many possibilities. A seemingly ordinary carport becomes a multifunctional station in which you can not only park your car, but also charge your batteries thanks to the SunRoof solar roof installation. This technology is adapted to collect solar energy and convert it into electricity.",
                "The body has been shaped in such a way as to create a kind of “cabinet” on one side in which we can easily connect a battery or other devices that need charging. The rest of the space is dedicated to storing other things, such as bicycles or electric scooters. On the opposite side, an openwork structure was chosen, which will eventually be covered with plants. Thanks to this, it will additionally seal the space and have a positive effect on the local ecosystem.",
                "Highly advanced technologies are combined with a traditional wooden structure, thus paying homage to natural materials. This treatment was also aimed at the greatest versatility of the facility, thanks to which it will look great in any environment, both modern and old-fashioned. All this makes Carport Pro the perfect solution for everyone!",
            ],
            images: [
                ImgCarportHonor01,
                ImgCarportHonor02,
                ImgCarportHonor03,
                ImgCarportHonor04,
            ]
        }
    ];

    return (
        <div className="flex flex-col">
            {
                winners.map(winner => (
                    <div key={winner.prize} className="h-full w-full bg-inherit flex flex-col lg:flex-row items-center">
                        <div className="h-full sm:w-full lg:w-5/12 bg-inherit flex flex-col">
                            <div className="h-1/4 w-full sm:mb-5 lg:mb-0 bg-inherit flex flex-col">
                                <div className="h-auto w-full bg-inherit flex flex-col">
                                    <div className="h-auto mt-10 mb-3 font-openSansLight font-semibold text-graphite">
                                        <small>In category: {winner.category}</small>
                                        <br />{winner.prize}
                                    </div>
                                    <div className="h-auto w-full flex flex-row">
                                        <div className="h-auto w-auto mr-6 font-openSansLight font-light text-graphite text-sm">
                                            Designer:
                                        </div>
                                        <div className="h-auto w-auto font-openSansLight font-light text-graphite text-sm">
                                            {winner.designer}
                                        </div>
                                    </div>
                                    <div className="h-auto w-full mt-1 flex flex-row">
                                        <div className="h-auto w-auto mr-9 font-openSansLight font-light text-graphite text-sm">
                                            Project:
                                        </div>
                                        <div className="h-auto w-auto font-openSansLight font-light text-graphite text-sm">
                                            {winner.project}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4 sm:mb-0 mt-5 w-full bg-inherit">
                                <div className="w-full bg-inherit font-openSansLight font-light text-graphite text-sm">
                                    {winner.lines.map(line => (
                                        <p key={line} className="mb-5">{line}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="lg:h-1/2 xl:h-full sm:w-full sm:mt-4 md:mt-0 lg:w-7/12 bg-inherit flex flex-col">
                            <div className="h-full w-full lg:hidden">
                                <Carousel showThumbs={false} showStatus={false}>
                                    {
                                        winner.images.map((img, idx) => (
                                            <div key={`img-${idx}`} className="h-auto w-full sm:mb-0 2xl:h-1/2 xl:h-2/5 sm:w-full flex">
                                                <img src={img} alt="" className="h-full w-full mx-auto flex" />
                                            </div>
                                        ))
                                    }
                                </Carousel>
                            </div>
                            <div className="hidden lg:flex">
                                <div className="h-full w-full lg:pl-6 2xl:pt-20 xl:pt-24 lg:pt-4 bg-inherit flex flex-row flex-wrap justify-evenly sm:justify-center lg:items-end">
                                    {
                                        winner.images.map((img, idx) => (
                                            <div key={`img2-${idx}`} className="h-auto xl:h-1/2 w-5/12 sm:w-1/2 sm:pr-2 sm:pb-2 flex">
                                                <img alt="" src={img} style={{ width: "100%" }} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default Winners2021;
