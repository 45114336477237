import React from "react";

function CompetitionFutureHouseCaption() {
  return (
    <div className="font-OpenSansLight font-light text-graphite md:mb-2 lg:mb-0 text-xl sm:text-4xl lg:text-3xl xl:text-4xl 2xl:text-4xl">
      Design of the Future.
    </div>
  );
}

export default CompetitionFutureHouseCaption;
